import React, { Component } from "react";
import Helmet from "./Helmet";
import ModalVideo from "react-modal-video";
import { FiClock, FiUser, FiChevronUp, FiStar } from "react-icons/fi";
import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";

import HeaderBlog from "../../../component/header/HeaderBlog";
import FooterTwo from "../../../component/footer/FooterTwo";

class SQL_FirstTables extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
  }

  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <HeaderBlog
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
          homeLink="/"
        />
        <br></br>
        <Helmet pageTitle="First Database Tables" />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190"
          data-black-overlay="7"
        >
          <div className="container">
            <img
              src="/assets/images/blog/sqlFirstTables/main.png"
              alt="yo"
            ></img>
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "black" }}>
                    First Database Tables with <br /> DBeaver
                  </h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li style={{ color: "black" }}>
                      <FiUser />
                      Jesse Madsen | October 15, 2022
                    </li>
                    <li style={{ color: "black" }}>20 min</li>
                    {/* <li style={{ color: "black" }}>
                      <FiClock />
                      October 7, 2022
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p>
                      If you have never made a database scheme or Table before
                      this is the quick start guide for you. This guide will not
                      teach you database theory, but it will only get you up and
                      going with DBeaver(open source) interface.
                      <ul>
                        <b>Pre-requisite</b>
                        <li>Database that you can connect to.</li>
                        <li>DBeaver is installed.</li>
                      </ul>
                      If you don't have the Pre-requisites, don't worry my quick
                      start Docker and Postgres tutorial can get you up and
                      going in 20min. Clicking below.
                    </p>
                    <a
                      href={`${process.env.PUBLIC_URL}/blog/content/SQL_Docker`}
                    >
                      <img
                        src="/assets/images/blog/sqlFirstTables/dp.png"
                        alt="Blog Images"
                      />
                    </a>
                    <p>
                      <br></br>
                      <h3>Check your DBeaver connection</h3>

                      <p>
                        Right click postgres-sample and Edit the Connection.
                      </p>
                      <img
                        src="/assets/images/blog/sqldocker/db5.png"
                        alt="DBeaver"
                      />
                      <br></br>
                      <br></br>
                      <br></br>
                      <p>
                        You will get a connection screen, confirming a good
                        connection.
                      </p>
                      <img
                        src="/assets/images/blog/sqlFirstTables/dbConnection.png"
                        alt="DBeaver"
                      />
                    </p>
                    <br></br>
                    <h3>Create a Schema</h3>
                    <p>
                      Right click your database and drill down unitl you can
                      create new Schema.
                    </p>

                    <img
                      src="/assets/images/blog/sqlFirstTables/dbSchemas.png"
                      alt="DBeaver"
                    />
                    <br></br>
                    <br></br>
                    <p>Name the Schema.</p>
                    <p>
                      <img
                        src="/assets/images/blog/sqlFirstTables/dbSchemas1.png"
                        alt="DBeaver"
                      />
                    </p>

                    <h3>Create a new Table</h3>
                    <p>
                      Expand your Schema and right click to create new Table.
                    </p>

                    <img
                      src="/assets/images/blog/sqlFirstTables/dbcreateTable.png"
                      alt="DBeaver"
                    />
                    <br></br>
                    <br></br>
                    <p>Name the Table.</p>
                    <p>
                      <img
                        src="/assets/images/blog/sqlFirstTables/dbcreateTable1.png"
                        alt="DBeaver"
                      />
                    </p>
                    <br></br>
                    <br></br>
                    <h3>Create a new Column</h3>
                    <p>Right click on table to Create a New Column.</p>
                    <p>
                      <img
                        src="/assets/images/blog/sqlFirstTables/dbcreateTable2.png"
                        alt="DBeaver"
                      />
                    </p>
                    <br></br>
                    <br></br>
                    <p>
                      I am creating my primary key(PK) name ID with a
                      serial(auto-fill number) datatype. Also, because it is a
                      the primary key, I activated the Not Null check.
                    </p>
                    <p>
                      <img
                        src="/assets/images/blog/sqlFirstTables/dbcreateTable3.png"
                        alt="DBeaver"
                      />
                    </p>
                    <br></br>
                    <br></br>
                    <p>
                      Now we have our column structure set. Note nothing has
                      been committed/save to the database yet.
                    </p>
                    <p>
                      <img
                        src="/assets/images/blog/sqlFirstTables/dbcreateTable4.png"
                        alt="DBeaver"
                      />
                    </p>
                    <br></br>
                    <br></br>
                    <p>
                      I added two more columns to the Table, and now its time to
                      save it.
                    </p>
                    <p>
                      <img
                        src="/assets/images/blog/sqlFirstTables/dbcreateTable5.png"
                        alt="DBeaver"
                      />
                    </p>
                    <br></br>
                    <br></br>
                    <p>
                      DBeaver will give you the SQL syntax of the Table
                      creation. Select persist to save the Table
                    </p>
                    <p>
                      <img
                        src="/assets/images/blog/sqlFirstTables/dbcreateTable6.png"
                        alt="DBeaver"
                      />
                    </p>
                    <br></br>
                    <br></br>
                    <h3>Create another table</h3>
                    <p>
                      I created another table for the team. The SQL syntax of
                      the new table creation table.{" "}
                    </p>
                    <p>
                      <img
                        src="/assets/images/blog/sqlFirstTables/dbcreateTable7.png"
                        alt="DBeaver"
                      />
                    </p>
                    <br></br>
                    <br></br>
                    <h3>Link tables together</h3>
                    <p>
                      We have created two tables, but they are not connected to
                      each other. On the team table, I made a player_id with the
                      anticipation of connecting the player table with the team
                      table with a foreign key relationship.{" "}
                    </p>
                    <p>
                      <img
                        src="/assets/images/blog/sqlFirstTables/dbcreateTable8.png"
                        alt="DBeaver"
                      />
                    </p>
                    <p>
                      <b>No link between them</b>
                    </p>

                    <br></br>
                    <br></br>
                    <p>
                      Double-click the player table to open it back up for
                      editing. We need to lable a constraint(assign which column
                      is the primary key). Open the Constraints tab on the right
                      and right click to create New Constraint.{" "}
                    </p>

                    <img
                      src="/assets/images/blog/sqlFirstTables/dbcreateTable9.png"
                      alt="DBeaver"
                    />
                    <br></br>
                    <br></br>
                    <img
                      src="/assets/images/blog/sqlFirstTables/dbcreateTable11.png"
                      alt="DBeaver"
                    />
                    <br></br>
                    <br></br>
                    <p>Set id as our primary key. </p>
                    <img
                      src="/assets/images/blog/sqlFirstTables/dbcreateTable12.png"
                      alt="DBeaver"
                    />
                    <br></br>
                    <br></br>
                    <h3>Set Forgien key Relationship</h3>
                    <p>
                      Double-click the team table and select the Foreign Keys
                      tab. Right Click in an open area to add New Foreign Key
                      Constraint.{" "}
                    </p>
                    <p>
                      <img
                        src="/assets/images/blog/sqlFirstTables/dbcreateTable10.png"
                        alt="DBeaver"
                      />
                    </p>
                    <br></br>
                    <br></br>
                    <p>
                      Select the player on the top reference table. Ok, then
                      save changes.{" "}
                    </p>
                    <p>
                      <img
                        src="/assets/images/blog/sqlFirstTables/dbcreateTable13.png"
                        alt="DBeaver"
                      />
                    </p>
                    <br></br>
                    <br></br>
                    <p>Now the tables are connected. </p>
                    <p>
                      <img
                        src="/assets/images/blog/sqlFirstTables/dbcreateTable14.png"
                        alt="DBeaver"
                      />
                    </p>
                    <p>
                      In this tutorial, we learned how to make tables and
                      connect them with the foreign key relationship. If you
                      have any comments, please leave a reply.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start BLog Comment Form  */}
        <div className="blog-comment-form pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h3 className="title mb--40 fontWeight500">Leave a Reply</h3>
                  <form action="#">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="rnform-group">
                          <input type="text" placeholder="Name" />
                        </div>
                        <div className="rnform-group">
                          <input type="email" placeholder="Email" />
                        </div>
                        <div className="rnform-group">
                          <input type="text" placeholder="Website" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="rnform-group">
                          <textarea
                            type="text"
                            placeholder="Comment"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="blog-btn">
                          {/* <a className="rn-button-style--2 btn-solid" href="#"></a> */}
                          <Link
                            className="rn-button-style--2 btn-solid"
                            to="/blog-details"
                          >
                            <span>SEND MESSAGE</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End BLog Comment Form  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );
  }
}
export default SQL_FirstTables;
