const BlogContent = [
  {
    images: "book1",
    title: "Introduction into Data Modeling",
    category: "Book Review",
    page: "data_modeling_steve_hoberman",
  },
  {
    images: "sqlDocker",
    title: "Postgres/SQL Docker",
    category: "Design",
    page: "SQL_Docker",
  },

  {
    images: "datamodeling",
    title: "First Database Tables with Dbeaver",
    category: "Design",
    page: "SQL_FirstTables",
  },
  {
    images: "04",
    title: " Getting tickets to the big show",
    category: "Development",
  },
  {
    images: "01",
    title: "A big ticket gone to be an interesting ",
    category: "Management",
  },
  {
    images: "02",
    title: "The Home of the Future Could Bebes",
    category: "Design",
  },
];

export default BlogContent;