import React, { Component } from "react";
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

class TabsTwo extends Component{
    render(){
        let 
        tab1 = "Main skills",
        tab2 = "Awards",
        tab3 = "Experience",
        tab4 = "Education & Certification";
        const { tabStyle } = this.props
        return (
          <div>
            {/* Start Tabs Area */}
            <div className="tabs-area">
              <div className="container">
                <div className="row">
                  <div className="col-lg-12">
                    <Tabs>
                      <TabList className={`${tabStyle}`}>
                        <Tab>{tab1}</Tab>
                        {/* <Tab>{tab2}</Tab> */}
                        <Tab>{tab3}</Tab>
                        <Tab>{tab4}</Tab>
                      </TabList>

                      <TabPanel>
                        <div className="single-tab-content">
                          <ul>
                            <li>
                              <a href="/service">
                                Software Architect{" "}
                                <span>
                                  {" "}
                                  - Getting all the moving pieces to work as
                                  one.
                                </span>
                              </a>
                            </li>
                            <li>
                              <a href="/service">
                                3D Design and Protoyping{" "}
                                <span> - Research and Development</span>
                              </a>
                            </li>
                            <li>
                              <a href="/service">
                                Desktop Application
                                <span> - Development</span>
                              </a>
                            </li>
                          </ul>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <div className="single-tab-content">
                          <ul>
                            <li>
                              <a href="/service">
                                Viax Dental Technologies logo Chief Information
                                Technology Officer
                                <span>- Viax Dental Technologies</span>
                              </a>{" "}
                              2016 - Present
                            </li>
                            {/*<li>
                              <a href="/service">
                                CSS Design Awards <span>- Winner</span>
                              </a>{" "}
                              2017 - 2018 
                            </li>*/}
                            <li>
                              <a href="/service">
                                Research and Development Engineering Manager{" "}
                                <span>- B&D Dental Technology</span>
                              </a>{" "}
                              2009- 2015
                            </li>
                          </ul>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <div className="single-tab-content">
                          <ul>
                            <li>
                              <a href="/service">
                                Bachelor of Science in Software Engineering
                                <span> - Snow College, UT</span>
                              </a>{" "}
                              2023
                            </li>
                            {/* <li>
                              <a href="/service">
                                Front-end Engineer<span> - Microsoft</span>
                              </a>{" "}
                              2017 - 2018
                            </li>
                            <li>
                              <a href="/service">
                                Software Engineer<span> - Alibaba </span>
                              </a>{" "}
                              2013- 2014
                            </li> */}
                          </ul>
                        </div>
                      </TabPanel>

                      <TabPanel>
                        <div className="single-tab-content">
                          <ul>
                            <li>
                              <a href="/service">
                                BSc In CSE
                                <span> - ABC University, Los Angeles, CA</span>
                              </a>{" "}
                              2010
                            </li>
                            <li>
                              <a href="/service">
                                Diploma in Computer Science
                                <span> - Gamma Technical Institute</span>
                              </a>{" "}
                              2009
                            </li>
                            <li>
                              <a href="/service">
                                Graphic Designer
                                <span> - ABC Institute, Los Angeles, CA</span>
                              </a>{" "}
                              2007
                            </li>
                          </ul>
                        </div>
                      </TabPanel>
                    </Tabs>
                  </div>
                </div>
              </div>
            </div>
            {/* End Tabs Area */}
          </div>
        );
    }
}



export default TabsTwo;