import React ,{ Component }from "react";
import {
  FiCpu,
  FiSettings,
  FiPackage,
  FiMonitor,
  FiGlobe,
  FiSunrise,
} from "react-icons/fi";

const ServiceList = [
  {
    icon: <FiGlobe />,
    title: "Project Management",
    description:
      "Leading world class teams, to deliver servises that exceeds clients expectations.",
  },
  {
    icon: <FiCpu />,
    title: "Product and R&D Strategy",
    description:
      "Bridging the gap between product development and R&D operations.",
  },
  {
    icon: <FiSunrise />,
    title: "Process Improvement",
    description: "Automating key processes so business can foucs on core goals.",
  },
  {
    icon: <FiMonitor />,
    title: "Software Development",
    description:
      "Desktop, Web, Backend, FrontEnd, Database ",
  },
  {
    icon: <FiPackage />,
    title: "Rapid Prototyping",
    description:
      "Hardware or Software, bringing concepts from the whiteboard to production floor.",
  },
  {
    icon: <FiSettings />,
    title: "3D CAD/CAM",
    description:
      "15 years working with the industries cutting edge platforms.",
  },
];


class ServiceThree extends Component{
    render(){
        const {column } = this.props;
        const ServiceContent = ServiceList.slice(0 , this.props.item);
        
        return(
            <React.Fragment>
                <div className="row">
                    {ServiceContent.map( (val , i) => (
                        <div className={`${column}`} key={i}>
                            <a href="/service-details">
                                <div className="service service__style--2">
                                    <div className="icon">
                                        {val.icon}
                                    </div>
                                    <div className="content">
                                        <h3 className="title">{val.title}</h3>
                                        <p>{val.description}</p>
                                    </div>
                                </div>
                            </a>
                        </div>
                    ))}
                </div>
            </React.Fragment>
        )
    }
}
export default ServiceThree;
