import React, { Component } from "react";
import Helmet from "./Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock, FiUser, FiChevronUp, FiStar } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';


import HeaderBlog from "../../../component/header/HeaderBlog";
import FooterTwo from "../../../component/footer/FooterTwo";


class SQL_Docker extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      
    };
    
  }
  
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    
    return (
      <React.Fragment>
        <HeaderBlog
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
          homeLink="/"
        />
        <br></br>
        <Helmet pageTitle="Postgres SQL Docker" />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190"
          data-black-overlay="7"
        >
          <div className="container">
          <img src="/assets/images/blog/sqldocker/dp_main.png" alt="yo"></img>
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "black" }}>
                    Docker Postgres/SQL <br /> Quick Start Guide
                  </h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li style={{ color: "black" }}>
                      <FiUser />
                      Jesse Madsen | October 13, 2022
                    </li>
                    <li style={{ color: "black" }}>20 min</li>
                    {/* <li style={{ color: "black" }}>
                      <FiClock />
                      October 7, 2022
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p>
                      We will be installing Docker Desktop and spinning up a
                      Postgres Database container. The installation time will
                      vary, but the whole process should take less than 20
                      minutes.
                      <ul>
                        <li>
                          <b>First Part</b>: Installing Docker Desktop
                        </li>
                        <li>
                          <b>Second Part</b>: Installing Postgres
                          image/container.
                        </li>
                        <li>
                          <b>Third Part</b>: Installing DBever and Connecting to
                          Postgres.
                        </li>
                      </ul>
                    </p>
                    <p>
                      <h3>First Part: Install Docker Destop</h3>

                      <p>
                        Docker Desktop will allow us to manage our containers.
                        If you are new to Docker, then you can check out Dockers
                        excellent reference guide by clicking the docker whale
                        below.
                      </p>
                      <a href="https://docs.docker.com/desktop/">
                        <img
                          src="/assets/images/blog/sqldocker/dockerLogo.png"
                          alt="Blog Images"
                        />
                      </a>

                      <h4></h4>
                      <p>
                        {" "}
                        <a href="https://www.docker.com/products/docker-desktop/">
                          Install Docker Desktop by downloading from{" "}
                          <u>Docker</u>.
                        </a>
                        <blockquote>
                          <p>
                            Note you must have{" "}
                            <a href="https://learn.microsoft.com/en-us/windows/wsl/install">
                              <u>WSL 2</u>
                            </a>{" "}
                            setup and working for Windows. <br></br>
                          </p>
                        </blockquote>
                        <p>
                          Once you have finished installing Docker Desktop it
                          will not start automatically. Search for Docker
                          Desktop in your Windows start-up menu and start it.
                        </p>
                      </p>
                      <div className="mt--20">
                        <img
                          src="/assets/images/blog/sqldocker/windows_startup.png"
                          alt="windows_startup"
                        />
                      </div>
                      <br></br>
                      <br></br>
                      <br></br>
                      <p>Sign in if prompted.</p>
                      <div className="mt--20">
                        <img
                          src="/assets/images/blog/sqldocker/docker_signIn.png"
                          alt="docker_signIn"
                        />
                      </div>
                    </p>
                    <br></br>
                    <br></br>
                    <br></br>
                    <p>
                      <h3>Second Part: Installing Postgres image/container.</h3>

                      <p>
                        First we need to open a new Windows Powershell.
                        <div className="mt--20">
                          <img
                            src="/assets/images/blog/sqldocker/powershell.png"
                            alt="docker_signIn"
                          />
                        </div>
                        <br></br>
                        <br></br>
                        Then we are going to paste in the following code, make
                        sure Docker Desktop is running!
                        <blockquote>
                          docker run -d -p 5432:5432 --name postgres-sample -e
                          POSTGRES_PASSWORD=password --restart always postgres
                        </blockquote>
                        <br></br>
                        <p>
                          There will be a container ID if you are succesful.
                        </p>
                        <a href="https://docs.docker.com/desktop/">
                          <img
                            src="/assets/images/blog/sqldocker/powershell2.png"
                            alt="Blog Images"
                          />
                        </a>
                        <br></br>
                        <br></br>
                        <p>
                          Your Docker Desktop will have a Postgres Instance
                          running, mapped to port 5453.
                        </p>
                        <a href="https://docs.docker.com/desktop/">
                          <img
                            src="/assets/images/blog/sqldocker/dockerDesktop.png"
                            alt="Blog Images"
                          />
                        </a>
                      </p>
                    </p>

                    <h3>
                      Third Part: Installing DBever and Connecting to Postgres.
                    </h3>

                    <p className="mt--40"> </p>
                    <p>
                      If you are not familiar with DBeaver, it is a free light
                      weight universal database GUI interface. We will be able
                      to connect to our new Postgress Database and run
                      PostgresSQL query commands. Click DBeaver below to
                      download.{" "}
                    </p>
                    <a href="https://docs.docker.com/desktop/">
                      <img
                        src="/assets/images/blog/sqldocker/beaver-head.png"
                        alt="DBeaver"
                      />
                    </a>
                    <p>
                      After you install DBeaver, we need to set up a new connect
                      to our Postgres Instance. Click File and Select New.
                    </p>
                    <img
                      src="/assets/images/blog/sqldocker/db21.png"
                      alt="DBeaver"
                    />
                    <br></br>
                    <br></br>
                    <p>
                      <p>
                        Dropdown options in the DBeaver folder and select the
                        database connection, then Next.
                      </p>
                      <img
                        src="/assets/images/blog/sqldocker/db3.png"
                        alt="DBeaver"
                      />
                      <br></br>
                      <br></br>
                      <p>Double click PostgresSQl.</p>
                      <img
                        src="/assets/images/blog/sqldocker/db4.png"
                        alt="DBeaver"
                      />
                      <br></br>
                      <br></br>
                      <p>
                        Right click postgres-sample and Edit the Connection.
                      </p>
                      <img
                        src="/assets/images/blog/sqldocker/db5.png"
                        alt="DBeaver"
                      />
                      <br></br>
                      <br></br>
                      <p>
                        Setting and testing the connection we need to set/check
                        three settings.
                        <ul>
                          <li>Database: postgres</li>
                          <li>Username: postgres</li>
                          <li>Password: password</li>
                        </ul>
                      </p>
                      <img
                        src="/assets/images/blog/sqldocker/db6.png"
                        alt="DBeaver"
                      />
                      <br></br>
                      <br></br>
                      <p>
                        Last step is to test connection. There will be a
                        Connection test pop-up if you are succesful. Note, your
                        Docker Destop needs to be on!
                      </p>
                      <img
                        src="/assets/images/blog/sqldocker/db7.png"
                        alt="DBeaver"
                      />
                    </p>

                    <p>
                      Thank you for following my blog. I hope it was informative
                      and was able to help you spin up a brand new Postgres
                      database. If you are interested in learning more on
                      databases, you can click the below link "My first Database
                      Tables."
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start BLog Comment Form  */}
        <div className="blog-comment-form pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h3 className="title mb--40 fontWeight500">Leave a Reply</h3>
                  <form action="#">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="rnform-group">
                          <input type="text" placeholder="Name" />
                        </div>
                        <div className="rnform-group">
                          <input type="email" placeholder="Email" />
                        </div>
                        <div className="rnform-group">
                          <input type="text" placeholder="Website" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="rnform-group">
                          <textarea
                            type="text"
                            placeholder="Comment"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="blog-btn">
                          {/* <a className="rn-button-style--2 btn-solid" href="#"></a> */}
                          <Link
                            className="rn-button-style--2 btn-solid"
                            to="/blog-details"
                          >
                            <span>SEND MESSAGE</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End BLog Comment Form  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );

  }
}
export default SQL_Docker;