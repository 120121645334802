import React, { Component } from "react";
import Helmet from "./Helmet";
import ModalVideo from 'react-modal-video';
import { FiClock, FiUser, FiChevronUp, FiStar } from "react-icons/fi";
import { Link } from 'react-router-dom';
import ScrollToTop from 'react-scroll-up';


import HeaderBlog from "../../../component/header/HeaderBlog";
import FooterTwo from "../../../component/footer/FooterTwo";


class data_modeling_steve_hoberman extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
      
    };
    
  }
  
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    
    return (
      <React.Fragment>
        <Helmet pageTitle="data_modeling" />
        <HeaderBlog
          headertransparent="header--transparent"
          colorblack="color--white"
          logoname="logo.png"
          homeLink="/"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190"
          data-black-overlay="7"
        >
          <div className="container">
        <img
          src="/assets/images/blog/dataModelingBook/dataModeling.png"
          alt="yo"
        ></img>
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title" style={{ color: "black" }}>
                    DATA Modeling Made Simple <br /> Book Review
                  </h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li style={{ color: "gold" }}>
                      <FiStar />
                      <FiStar />
                      <FiStar />
                      <FiStar />
                      <FiStar style={{ color: "grey" }} />
                    </li>
                    <li style={{ color: "black" }}>
                      <FiUser />
                      Jesse Madsen | October 7, 2022
                    </li>
                    <li style={{ color: "black" }}>2 min</li>
                    {/* <li style={{ color: "black" }}>
                      <FiClock />
                      October 7, 2022
                    </li> */}
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p>
                      Data Modeling Made Simple by Steve Hoberman provides an
                      introductory to mid-level guide to the world of data
                      modeling and database entry. I found the book easy to
                      digest while not being overly verbose. If you know nothing
                      about data modeling and need a guide, this book will get
                      you started on the right path.{" "}
                    </p>
                    <div className="thumbnail">
                      <img
                        src="/assets/images/blog/dataModelingBook/dataModeling2.png"
                        alt="Blog Images"
                      />
                    </div>
                    <p className="mt--40">
                      Data surround us. If you are a software engineer like me,
                      you deal daily with data modeling questions. From simple
                      API queries to storing and retrieving customers'
                      statistics, the question is how we can capitalize this
                      data to its maximum potential. But before we can leverage
                      our data, we need to understand its relationships to our
                      business rules.{" "}
                    </p>
                    <p>
                      Data Modeling is Data Communication. Hoberman understands
                      this and will use real world examples to help busniess
                      professionals and data novices to communicate their data
                      in an impactful, organize manner. From the forward, Wayne
                      W.Eckerson from the Data Warehousing Institue,{" "}
                    </p>
                    <blockquote className="rn-blog-quote">
                      ... a good data model serves as lingua franca between
                      business and information-technoloy prpfessionals. It
                      provides a shared understanding of the business that
                      aligns business and technical professionals ...
                    </blockquote>

                    <p>
                      If you don’t know why data models are so fundamental to
                      business, then you are in the right place and maybe Data
                      Modeling Made Simple is the right book for you start with.
                    </p>
                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                      <div className="thumbnail">
                        <img
                          className="w-100"
                          src="/assets/images/blog/dataModelingBook/blog-book1.png"
                          alt="BLog Images"
                        />
                        <span></span>
                      </div>
                      <div className="content">
                        <p></p>
                        <h4 className="title">Why do we need data models?</h4>
                        <ul className="list-style">
                          <li>
                            We can use data models to understand an existing
                            application or process.
                          </li>
                          <li>
                            We can use them to manage risk by mapping out
                            concepts and interactions.
                          </li>
                          <li>Good models help define business rules</li>
                          <li>Eductate team memebers.</li>
                        </ul>
                        <h4 className="title">What does a good model have?</h4>
                        <ul className="list-style">
                          <li>
                            <b>Relationships</b>, how all the pices fit
                            together.
                          </li>
                          <li>
                            <b>Scope</b>, adds clarity and focues to the model.
                          </li>
                          <li>
                            <b>Abstraction</b>, great models have abstracted
                            away unnecessary details.
                          </li>
                        </ul>
                      </div>
                    </div>

                    <p className="mt--25 mt_sm--5"></p>
                    {/* <div className="video-wrapper position-relative mb--40">
                      <div className="thumbnail">
                        <img
                          src="/assets/images/blog/bl-big-01.jpg"
                          alt="Blog Images"
                        />
                      </div>
                      <ModalVideo
                        channel="youtube"
                        isOpen={this.state.isOpen}
                        videoId="ZOoVOfieAF8"
                        onClose={() => this.setState({ isOpen: false })}
                      />
                      <button
                        className="video-popup position-top-center"
                        onClick={this.openModal}
                      >
                        <span className="play-icon"></span>
                      </button>
                    </div> */}
                    <p className="mb--0">
                      Some of the skill you could develope or improve by reading
                      this book.
                      <ol>
                        <li>
                          You will learn when a data model is needed, and which
                          type of data model is most effective for each
                          situation.
                        </li>
                        <li>
                          You will he able to read a data model of any size and
                          complexity with the same confidence as reading a book.
                        </li>
                        <li>
                          You will be able to build a fully normalized
                          relational data model as well as an easily navigable
                          dimensional model.
                        </li>
                        <li>
                          You will be able to apply techniques to turn a logical
                          data model into an efficient physical design.
                        </li>
                        <li>
                          You will be able to leverage several templates to make
                          requirements gathering more efficient and accurate.
                        </li>
                        <li>
                          You will be able to explain all ten categories of the
                          Data Model Scorecard.
                        </li>
                        <li>
                          You will learn practical advice on improving your
                          working relationships with others.
                        </li>
                        <li>
                          You will appreciate the impact unstructured data has
                          and will have on our data modeling deliverables.
                        </li>
                        <li>You will learn basic UML concepts.</li>
                        <li>
                          You will be able to put data modeling in context with
                          XML, metadata, and agile development.
                        </li>
                      </ol>
                    </p>
                    <p className="mb--0">
                      If a picture is worth a thousand words then a good data
                      model is priceless. Data modeling will help you define and
                      organize any business process or application. The
                      collaboration between high-level business processes and it
                      technical implementation will become seamless.
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start BLog Comment Form  */}
        <div className="blog-comment-form pb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner">
                  <h3 className="title mb--40 fontWeight500">Leave a Reply</h3>
                  <form action="#">
                    <div className="row">
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="rnform-group">
                          <input type="text" placeholder="Name" />
                        </div>
                        <div className="rnform-group">
                          <input type="email" placeholder="Email" />
                        </div>
                        <div className="rnform-group">
                          <input type="text" placeholder="Website" />
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-12 col-12">
                        <div className="rnform-group">
                          <textarea
                            type="text"
                            placeholder="Comment"
                          ></textarea>
                        </div>
                      </div>
                      <div className="col-lg-12">
                        <div className="blog-btn">
                          {/* <a className="rn-button-style--2 btn-solid" href="#"></a> */}
                          <Link
                            className="rn-button-style--2 btn-solid"
                            to="/blog-details"
                          >
                            <span>SEND MESSAGE</span>
                          </Link>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End BLog Comment Form  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <FooterTwo />
      </React.Fragment>
    );

  }
}
export default data_modeling_steve_hoberman;